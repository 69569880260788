import React, { Component } from "react";
import { Modal, Input, Button, Image, Segment, Icon, Form, TextArea, Loader, Dimmer } from "semantic-ui-react";

import XIcon from "../assets/x.svg";

class TagButton extends Component {


  constructor(props) {
    super(props);
    this.state = {
    }

  }


  componentDidMount() {
  }


  render() {

    const { type, text, image, altText, onRemove, aspectType } = this.props;

    let width, height;
    switch (aspectType) {
      case "wide":
        width = 192;
        height = 80;
        break;
      case "square":
        width = 80;
        height = 80;
        break;
      default:
        width = null;
        height = 80;
    }


    let content = null;
    if (type == "image") {
      content=(
        <Image 
            src={image} 
            alt={altText}
            style={{ width: "100%", height: "100%", borderRadius: 4, objectFit: 'cover' }} 
          />
      )
    } else if (type == 'text') {
      content=(
        <div 
              style={{ height: "100%", borderRadius: 4, padding: 14, display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white' }} 
            >{text}
          </div>
      )
    }

    return (
      <div 
        style={{ position: "relative", width, height, borderRadius: 4, marginRight: 12, marginBottom: 12, objectFit: 'cover', backgroundColor: '#111111' }}
        onClick={() => onRemove()}
        >
        { content }
        <div 
          className="tagButtonOverlay"
          >
            <Image
              className="removeButton"
              src={XIcon} 
              alt="remove" 
              onClick={() => console.log('trash')} 
            />
        </div>
      </div>
    );

  }

}

export default TagButton;