import React from "react";
import "./Home.css";

const TermsOfService = () => {
  return (
    <div>
      <div style={{ color: 'white', padding: 32 }}>Legal
PLEASE READ THESE TERMS OF SERVICE (“AGREEMENT” OR “TERMS OF SERVICE”) CAREFULLY BEFORE USING THE SERVICES OFFERED BY FILMDIFFUSION.AI. THIS AGREEMENT SETS FORTH THE LEGALLY BINDING TERMS AND CONDITIONS FOR YOUR USE OF THE PRE FILM INC. WEBSITE AND ALL RELATED SERVICES, INCLUDING, WITHOUT LIMITATION, ANY FEATURES, CONTENT, WEBSITES (INCLUDING app.filmdiffusion.ai) OR APPLICATIONS OFFERED FROM TIME TO TIME BY PRE FILM INC. IN CONNECTION THEREWITH (COLLECTIVELY “SERVICE(S)”). BY USING THE SERVICES IN ANY MANNER, YOU AGREE TO BE BOUND BY THIS AGREEMENT.
Acceptance of Terms of Service
The Service is offered subject to acceptance without modification of all of these Terms of Service and all other operating rules, policies and procedures that may be published from time to time in connection with the Services by PRE FILM INC.. In addition, some services offered through the Service may be subject to additional terms and conditions promulgated by PRE FILM INC. from time to time; your use of such services is subject to those additional terms and conditions, which are incorporated into these Terms of Service by this reference.
PRE FILM INC. may, in its sole discretion, refuse to offer the Service to any person or entity and change its eligibility criteria at any time. This provision is void where prohibited by law and the right to access the Service is revoked in such jurisdictions.
DMCA and Takedowns Policy
Pre Film utilizes artificial intelligence systems to produce the Assets. Such Assets may be unintentionally similar to copyright protected material or trademarks held by others. We respect rights holders internationally and we ask our users to do the same. If you believe your copyright or trademark is being infringed by the Service, please write to support@backlot.studio and we will process and investigate your request and take appropriate actions under the Digital Millennium Copyright Act and other applicable intellectual property laws with respect to any alleged or actual infringement.
Modification of Terms of Service
At its sole discretion, PRE FILM INC. may modify or replace any of the Terms of Service, or change, suspend, or discontinue the Service (including without limitation, the availability of any feature, database, or content) at any time by posting a notice on the PRE FILM INC. websites or Service or by sending you an email. PRE FILM INC. may also impose limits on certain features and services or restrict your access to parts or all of the Service without notice or liability. It is your responsibility to check the Terms of Service periodically for changes. Your continued use of the Service following the posting of any changes to the Terms of Service constitutes acceptance of those changes.
Trademarks and Patents
All PRE FILM INC. logos, marks and designations are trademarks or registered trademarks of PRE FILM INC.. All other trademarks mentioned in this website are the property of their respective owners. The trademarks and logos displayed on this website may not be used without the prior written consent of PRE FILM INC. or their respective owners. Portions, features and/or functionality of PRE FILM INC.’s products may be protected under PRE FILM INC. patent applications or patents.
Licensing Terms
Subject to your compliance with this Agreement, the conditions herein and any limitations applicable to Pre Film or by law: (i) you are granted a non-exclusive, limited, non-transferable, non-sublicensable, non-assignable, freely revocable license to access and use the Service for business or personal use; (ii) you own all Assets you create with the Services and (iii) we hereby assign to you all rights, title and interest in and to such Assets for your personal or commercial use. Otherwise, Pre Film reserves all rights not expressly granted under these Terms of Service. Each person must have a unique account and you are responsible for any activity conducted on your account. A breach or violation of any of our Terms of Service may result in an immediate termination of your right to use our Service.
By using the Services, you grant to Pre Film, its successors, and assigns a perpetual, worldwide, non-exclusive, sublicensable, no-charge, royalty-free, irrevocable copyright license to use, copy, reproduce, process, adapt, modify, publish, transmit, prepare Derivative Works of, publicly display, publicly perform, sublicense, and/or distribute text prompts and images you input into the Services, or Assets produced by the Service at your direction. This license authorizes Pre Film to make the Assets available generally and to use such Assets as needed to provide, maintain, promote and improve the Services, as well as to comply with applicable law and enforce our policies. You agree that this license is provided with no compensation paid to you by Pre Film for your submission or creation of Assets, as the use of the Services by you is hereby agreed as being sufficient compensation for the grant of rights herein. You also grant each other user of the Service a worldwide, non-exclusive, royalty-free license to access your publicly available Assets through the Service, and to use those Assets (including to reproduce, distribute, modify, display, and perform it) only as enabled by a feature of the Service. The license to Pre Film survives termination of this Agreement by any party, for any reason.
Fees and Payments
Pre Film offers a free and paid Service. You can learn more about our paid subscription offering here. You can sign up for a monthly subscription, payable in U.S. dollars, that will automatically renew on a monthly annual basis. You can stop using the Service and cancel your subscription at any time through the website or by emailing us at support@backlot.studio. If you cancel your subscription, you will not receive a refund or credit for any amounts that have already been billed or paid. Pre Film reserves the right to change its prices at any time. If you are on a subscription plan, changes to pricing will not apply until your next renewal or thirty (30) days after notice, whichever is later.
Unless otherwise stated, your subscription fees (“Fees”) do not include federal, state, local, and foreign taxes, duties, and other similar assessments (“Taxes”). You are responsible for all Taxes associated with your purchase and we may invoice you for such Taxes. You agree to timely pay such Taxes and provide us with documentation showing the payment or additional evidence that we may reasonably require. If any amount of your Fees are past due, we may suspend your access to the Services after we provide you written notice of late payment. You may not create more than one account to benefit from the Free tier of our Services. If we believe you are not using the Free tier in good faith, we may charge you standard fees or stop providing access to the Services.
Rules and Conduct
As a condition of use, you promise not to use the Service for any purpose that is prohibited by the Terms of Service. By way of example, and not as a limitation, you shall not (and shall not permit any third party to) take any action (including making use of the Site, any Assets or our models or derivatives of our models) that: would constitute a violation of any applicable law, rule or regulation; infringes upon any intellectual property or other right of any other person or entity; is threatening, abusive, harassing, defamatory, libelous, deceptive, fraudulent, invasive of another’s privacy, tortious, obscene, offensive, furthering of self-harm or profane; creates Assets that exploits or abuses children; generates or disseminates verifiably false information with the purpose of harming others; impersonates or attempts to impersonate others; generates or disseminates personally identifying or identifiable information; creates Assets that implies or promotes support of a terrorist organization; creates Assets that condone or promote violence against people based on any protected legal category.
Further, you shall not (directly or indirectly): (i) take any action that imposes or may impose an unreasonable or disproportionately large load on Pre Film’s (or its third party providers’) infrastructure; (ii) interfere or attempt to interfere with the proper working of the Service or any activities conducted on the Service; (iii) bypass any measures Pre Film may use to prevent or restrict access to the Service (or parts thereof); (iv) use any method to extract data from the Services, including web scraping, web harvesting, or web data extraction methods, other than as permitted through an allowable API; (v) reverse assemble, reverse compile, decompile, translate or otherwise attempt to discover the source code or underlying components of models, algorithms, and systems of the Services that are not open (except to the extent such restrictions are contrary to applicable law); and (vi) reproduce, duplicate, copy, sell, resell or exploit any portion of the Site, use of the Site, or access to the Site or any contact on the Site, without our express written permission.
Termination
PRE FILM INC. may terminate your access to all or any part of the Service at any time if you fail to comply with these Terms of Service, which may result in the forfeiture and destruction of all information associated with your account. Further, either party may terminate the Services for any reason and at any time upon written notice. If you wish to terminate your account, you may do so by following the instructions on the Service. Any fees paid hereunder are non-refundable. Upon any termination, all rights and licenses granted to you in this Agreement shall immediately terminate, but all provisions hereof which by their nature should survive termination shall survive termination, including, without limitation, warranty disclaimers, indemnity and limitations of liability.
Indemnification
You shall defend, indemnify, and hold harmless PRE FILM INC., its affiliates and each of its, and its affiliates employees, contractors, directors, suppliers and representatives from all liabilities, losses, claims, and expenses, including reasonable attorneys’ fees, that arise from or relate to (i) your use or misuse of, or access to, the Service, or (ii) your violation of the Terms of Service or any applicable law, contract, policy, regulation or other obligation. PRE FILM INC. reserves the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will assist and cooperate with PRE FILM INC. in connection therewith.
Limitation of Liability
IN NO EVENT SHALL PRE FILM INC. OR ITS DIRECTORS, EMPLOYEES, AGENTS, PARTNERS, SUPPLIERS OR CONTENT PROVIDERS, BE LIABLE UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR ANY OTHER LEGAL OR EQUITABLE THEORY WITH RESPECT TO THE SERVICE (I) FOR ANY LOST PROFITS, DATA LOSS, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER, OR SUBSTITUTE GOODS OR SERVICES, (II) FOR YOUR RELIANCE ON THE SERVICE OR (III) FOR ANY DIRECT DAMAGES IN EXCESS (IN THE AGGREGATE) OF THE FEES PAID BY YOU FOR THE SERVICE OR, IF GREATER, $500. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS AND EXCLUSIONS MAY NOT APPLY TO YOU.
Disclaimer
ALL USE OF THE SERVICE AND ANY CONTENT IS UNDERTAKEN ENTIRELY AT YOUR OWN RISK. THE SERVICE (INCLUDING, WITHOUT LIMITATION, THE PRE FILM INC. WEB APP AND ANY CONTENT) IS PROVIDED “AS IS” AND “AS AVAILABLE” AND IS WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED. SOME STATES DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
Age Requirements
By accessing the Services, you confirm that you’re at least 13 years old and meet the minimum age of digital consent in your country. If you are not old enough to consent to our Terms of Service in your country, your parent or guardian must agree to this Agreement on your behalf.
Please ask your parent or guardian to read these terms with you. If you’re a parent or legal guardian, and you allow your teenager to use the Services, then these terms also apply to you and you’re responsible for your teenager’s activity on the Services. No assurances are made as to the suitability of the Assets for you.
Miscellaneous
The Terms of Service are the entire agreement between you and PRE FILM INC. with respect to the Service, and supersede all prior or contemporaneous communications and proposals (whether oral, written or electronic) between you and PRE FILM INC. with respect to the Service. If any provision of the Terms of Service is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that the Terms of Service will otherwise remain in full force and effect and enforceable. The failure of either party to exercise in any respect any right provided for herein shall not be deemed a waiver of any further rights hereunder. PRE FILM INC. shall not be liable for any failure to perform its obligations hereunder due to any cause beyond PRE FILM INC.’s reasonable control. The Terms of Service are personal to you, and are not assignable or transferable by you except with PRE FILM INC.’s prior written consent. PRE FILM INC. may assign, transfer or delegate any of its rights and obligations hereunder without consent. No agency, partnership, joint venture, or employment relationship is created as a result of the Terms of Service and neither party has any authority of any kind to bind the other in any respect. Except as otherwise provided herein, all notices under the Terms of Service will be in writing and will be deemed to have been duly given when received, if personally delivered or sent by certified or registered mail, return receipt requested; when receipt is electronically confirmed, if transmitted by facsimile or e-mail; or two days after it is sent, if sent for next day delivery by recognized overnight delivery service.
Contact Us
For questions regarding the Service, you can get in touch by emailing us at support@backlot.studio.</div>
    </div>
  );
};

export default TermsOfService;
