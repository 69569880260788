import './App.css';
import "normalize.css";
import 'semantic-ui-css/semantic.min.css'

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import React from 'react';

import Home from './components/Home';
import AccountSettings from './components/AccountSettings';
import Login from './components/Login';


import TermsOfService from './components/TermsOfService';
import PrivacyPolicy from './components/PrivacyPolicy';

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/goldengate" exact element={<Home />} />
          <Route path="/tos" exact element={<TermsOfService />} />
          <Route path="/privacy" exact element={<PrivacyPolicy />} />
          <Route path="/account" exact element={<AccountSettings />} />
          <Route path="/login" exact element={<Login />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
