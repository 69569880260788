import React from "react";
import { Link } from "react-router-dom";
import "./Home.css";

const AppFooter = () => {
  return (
    <div className="mainFooter">
      <div className="text">© Copyright 2022 Pre Film, Inc. All rights reserved.</div>
      <Link to="/tos" style={{ marginLeft: 30, color: '#cecece' }}>Terms of Service</Link>
      <Link to="/privacy" style={{ marginLeft: 30, color: '#cecece' }}>Privacy Policy</Link>
    </div>
  );
};

export default AppFooter;
