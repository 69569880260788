import { Link } from "react-router-dom";

import React, { Component } from "react";
// import OpenAIAPI from 'openai-api-node';
import { Button, Input, Dimmer, Segment, Image, Progress, Modal, Icon, Loader, Dropdown, Placeholder } from "semantic-ui-react";

import Toggle from "react-toggle";
import "react-toggle/style.css";

// import { WithContext as ReactTags } from "react-tag-input";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions, connectFunctionsEmulator, httpsCallable } from "firebase/functions";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";

import Logo from "../assets/logo.svg";
import RetryIcon from "../assets/retry.svg";

import "./Home.css";

import UpgradeModal from "./UpgradeModal";
import SettingsModal from "./SettingsModal";
import FullscreenImageModal from './FullscreenImageModal';

import './Home.css';

import tagDictionary from "../tags_data/tags.json";

// import compat firebase for Firebase Auth
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import AppFooter from "./AppFooter";

import CreateTab from "./CreateTab";
import EditTab from "./EditTab";
import ReviewTab from "./ReviewTab";
// import LoginModal from "./LoginModal";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA4mgaIT2nq5UQbEQf7t--NcyrnnqfAApU",
  authDomain: "filmdiffusion.firebaseapp.com",
  projectId: "filmdiffusion",
  storageBucket: "filmdiffusion.appspot.com",
  messagingSenderId: "649715566050",
  appId: "1:649715566050:web:89ef9199df86d5f1b6a5bb",
  measurementId: "G-MP5HSH7M0J",
};

// init firebase app
firebase.initializeApp(firebaseConfig);

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const functions = getFunctions(app);
const db = getFirestore(app);
const auth = getAuth(app);



// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  // signInSuccessUrl: '/signedIn',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      requireDisplayName: false,
      disableSignUp: { status: true, adminEmail: "" },
    }
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
  ],

  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};







const customFoxLocations = [
  { 
    key: "30s_bar",
    text: "30s Bar",
    thumbnail: "",
    prompt_override: "film still, beautiful cinematography, (1930s), crowded san francisco dive bar interior, [INSERT_TEXT],  gritty bar, (grungy), small poor bar, brick walls, dirty, dark, cold, (depth of field), (cinematic lighting), photorealistic, 4k, 8k, dusty volumetric lighting, cinematic still, directed by Martin Scorsese, arri alexa mini, crowded, (western film), full color, good framing, ((cinematic color grade))",
    negprompt_override: "(((black and white))), painting, digital art, neon, fancy, empty, grainy, noisy, pixelated, low-resolution, distortion, modern, 2000s, 1990s, 1980s, 1970s, 1960s, 1950s, ((tv screen))"
  },
  { 
    key: "30s_city",
    text: "30s City",
    thumbnail: "",
    prompt_override: "film still, beautiful cinematography, 1930s san francisco, city street, [INSERT_TEXT], daily life scene, shops, depth of field, cinematic lighting, bokeh,  volumetric lighting, cinematic still, directed by Martin Scorsese, arri Alexa mini, full color, highly detailed, 4k, sharp focus, cinematography by emmanuel lubezki, scene from the movie,  good framing",
    negprompt_override: "(black and white), painting, digital art, neon, fancy, empty, ugly, bad, amateur"
  },
  { 
    key: "30s_train",
    text: "30s Train",
    thumbnail: "",
    prompt_override: "film still, [beautiful cinematography, 1930s train interior, san francisco, [INSERT_TEXT], depth of field, cinematic lighting, bokeh,  volumetric lighting, cinematic still, directed by Martin Scorsese, arri alexa mini, full color, highly detailed, 4k, sharp focus, cinematography by emmanuel lubezki, scene from the movie, good framing",
    negprompt_override: "(black and white), painting, digital art, neon, fancy, empty, ugly, bad, amateur"
  },
  { 
    key: "30s_crowd_ballroom_interior",
    text: "30s Crowd (Ballroom Interior)",
    thumbnail: "",
    prompt_override: "film still, beautiful cinematography, ((1930s)) small ballroom interior, [INSERT_TEXT], san francisco, crowded small ballroom party, chandeliers, dark, night time,  depth of field, cinematic lighting, bokeh, (cold color tones),  volumetric lighting, cinematic still, directed by Martin Scorsese, arri alexa mini, full color, highly detailed, 4k, sharp focus, cinematography by emmanuel lubezki, scene from the movie, good framing",
    negprompt_override: "(black and white), painting, digital art, neon, fancy, empty, ugly, bad, amateur"
  },
  { 
    key: "30s_crowd_factory_workers_exterior",
    text: "30s Crowd (Factory Workers Exterior)",
    thumbnail: "",
    prompt_override: "film still, beautiful cinematography, ((1930s)) outside of a grungy factory, [INSERT_TEXT], san francisco (city), poor crowd trying to get to work, crowded, desperate, factory workers, protest, sad, night time,  depth of field, cinematic lighting, bokeh, (cold color tones),  volumetric lighting, cinematic still, directed by Martin Scorsese, arri alexa mini, full color, highly detailed, 4k, sharp focus, cinematography by emmanuel lubezki, scene from the movie, good framing",
    negprompt_override: "(black and white), painting, digital art, neon, fancy, empty, ugly, bad, amateur, dinner, landscape, greenery"
  },
  { 
    key: "30s_ferries",
    text: "30s Ferries",
    thumbnail: "",
    prompt_override: "film still, beautiful cinematography, ((1930s)) tourist fancy ferry, san francisco (city), depth of field, cinematic lighting, bokeh, (cold color tones), volumetric lighting, cinematic still, directed by Martin Scorsese, arri alexa mini, full color, highly detailed, 4k, sharp focus, cinematography by emmanuel lubezki, scene from the movie, good framing",
    negprompt_override: "(black and white), painting, digital art, neon, fancy, empty, ugly, bad, amateur"
  },
  { 
    key: "finnochios",
    text: "Finnochios",
    thumbnail: "",
    prompt_override: "film still, [INSERT_TEXT], beautiful cinematography, 1930s (drag bar) interior, (grungy bar) ((drag queen)), (((cabaret bar))), dance show, night club, stage, cocktails, queer, red dresses, drag queen ((dancing)), motion blur, smoke, colorful, exciting, depth of field, stage lighting, theatrical lighting, bokeh,  volumetric lighting, cinematic still, directed by Martin Scorsese, arri alexa mini, full color, photorealistic, highly detailed, 4k, sharp focus, cinematography by emmanuel lubezki, scene from the movie Chicago, good framing",
    negprompt_override: "(black and white), painting, digital art, neon, ((fancy)), empty, ugly, bad, amateur, exterior, wealthy, rich, yellow, warm, bright, daytime"
  },
  { 
    key: "messina_house",
    text: "Messina House",
    thumbnail: "",
    prompt_override: "dusk, film still, [INSERT_TEXT], cinematography, (1930s)  (poor apartment) ((interior)), ((san francisco)), antiques, vintage, depth of field, (((poverty))), ((nighttime)), (cramped apartment), family apartment,  depressing, sad movie, ((dusty)),  working class, falling-apart house interior, cracks in the walls,  cinematic lighting, bokeh,  volumetric lighting, dark, cinematic still,  arri alexa mini, full color, photorealistic, highly detailed, 4k, hdr10, sharp focus, cinematography by emmanuel lubezki, good composition, (lamp lighting), fog, curtains, ((pattern wallpaper)), kitchen",
    negprompt_override: "(((black and white))), painting, digital art, neon, ((fancy)), empty, ugly, bad, amateur, (exterior), (wealthy), rich, bright, daytime, minimalist, modern, sunny, expensive, happy, ((spacious)), golden hour, comedy, 1970s, bedroom"
  },
  { 
    key: "office",
    text: "Office",
    thumbnail: "",
    prompt_override: "((dark)), film still, [INSERT_TEXT], cinematography, small office, (((1930s))),  (((1930s office interior))), san francisco, secretary, ((dark room)), ((smoky lighting)), antiques, vintage,  wood furniture, depth of field,  ((dust particles)), ((volumetric lighting)),  cinematic lighting, bokeh, cold, cinematic still,  arri alexa mini, photorealistic, highly detailed, 4k, hdr10, sharp focus, cinematography by emmanuel lubezki, good composition,  directed by francis ford coppola, mafia office, classy, god rays, window shades, characters",
    negprompt_override: "(((black and white))), painting, digital art, ((sepia)), ((colorful)), spacious, empty, ugly, bad, amateur, (exterior), warm color, bright, daytime, minimalist, modern, sunny, expensive, happy, ((spacious)), golden hour, comedy, 1970s, bedroom, plants, camera, golden gate bridge, food, screen, ((computer)), fbi, government, out-of-frame, random, spider, ((empty room))"
  },
  { 
    key: "restaurant",
    text: "Restaurant",
    thumbnail: "",
    prompt_override: "((dark)), film still, [INSERT_TEXT], cinematography, ((restaurant)), (((1930s))),  (((1930s office interior))), san francisco, ((dark room)), ((smoky lighting)),depth of field,  ((dust particles)), ((volumetric lighting)),  cinematic lighting, bokeh, cold, cinematic still,  arri alexa mini, photorealistic, highly detailed, 4k, hdr10, sharp focus, cinematography by emmanuel lubezki, good composition",
    negprompt_override: "(((black and white))), painting, digital art, ((sepia)), ((colorful)), spacious, empty, ugly, bad, amateur, (exterior), warm color, bright, daytime, minimalist, modern, sunny, expensive, happy, ((spacious)), golden hour, comedy, 1970s, bedroom, plants, camera, golden gate bridge, food, screen, ((computer)), fbi, government, out-of-frame, random, spider, ((empty room)), fire"
  },
  { 
    key: "golden_gate_bridge_construction",
    text: "Golden Gate Bridge Construction",
    thumbnail: "",
    prompt_override: "construction workers, golden gate bridge under-construction, [INSERT_TEXT], 1933, unbuilt, incomplete, construction equipment, cinematography, film still, full color, depth of field, cinematic lighting, arri alexa, good composition, highly detailed 4k",
    negprompt_override: "(((black and white))), painting, digital art, ((wires)), completed  bridge, on the ground, desaturated, modern, text, post-card, inaccurate"
  },
  { 
    key: "golden_gate_top_bridge",
    text: "Golden Gate Bridge (Top of Bridge)",
    thumbnail: "",
    prompt_override: "(((construction workers standing on top of golden gate bridge towers))), person, close-up, [INSERT_TEXT], golden gate bridge under-construction, ((1933)), unbuilt, incomplete, construction equipment, cinematography, fog, film still, full color, depth of field, cinematic lighting, arri alexa, good composition, highly detailed 4k, above bridge, looking down",
    negprompt_override: "(((black and white))), painting, digital art, ((cables)), completed  bridge, on the ground, desaturated, modern, text, post-card, inaccurate, posing, low angle, wide angle"
  },
  { 
    key: "source_1",
    text: "Golden Gate Bridge (Before Construction)",
    thumbnail: "",
    shot_url: "https://storage.googleapis.com/filmdiffusion_public/golden_gate/a3ecb520f45ef1a1ed2c9341a50eec138ff7ae60287c53af2f24865e8c49afe5220e3b1fe0cf247c9146092014985bd47edf983bfb9bc0b0998ea87b62d9c62b45859fb44fda49e0c124f42cf2d98d52c54328aa86beea24b2f5b80012350f949b243af9.png",
    prompt_override: "film still, beautiful cinematography, 1930s construction worker on the golden gate bridge cables, cinematic, film still, full color, depth of field, volumetric lighting, beautiful, 1933, highly detailed, 4k",
    negprompt_override: "(((black-white))), painting, cartoony, digital art, unrealistic, desaturated, ((black and white)), ugly, post card, colorized, unnatural, bland, empty, (sepia), pixelated"
  },
  { 
    key: "",
    text: "Golden Gate Bridge (During Construction)",
    thumbnail: "",
    shot_url: "https://storage.googleapis.com/filmdiffusion_public/golden_gate/8a014365a72028d86da715feeb7a97c12ad28ad4fb30918d07fd8af2769583e00da1e622798aa8f96a0d4a71c448bde1c9520a1396ff360608cc64c226954bcb8ffa8cca99f840117000737943cdd5b934ab2e958b81801056ccd40aed46ed889333582e.png",
    prompt_override: "cinematic, movie still, ((1930s construction workers)), on the golden gate bridge 1933, cinematic, film still, (full color), depth of field, volumetric lighting, fog, beautiful, 1933, golden hour, hdr, bokeh, arri alexa, 50mm lens, beautiful, high contrast",
    negprompt_override: "(((black-white))), painting, cartoony, digital art, unrealistic, desaturated, ((black and white)), ugly, post card, colorized, unnatural, bland, empty, (sepia)"
  }
]








const subscriptions = {
  prod_Mfymhop406ekSX: 200,
  prod_MfylqFplyE2EKx: 500,
  prod_MfylbBzOvAtuAk: 1000,
};



class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {

      selectedTab: "CREATE",
      editImage: null,
      job_id: "",

      initLoading: true,
      user: null,

      creditsLoading: false,
      credits: -1000,
      subscription: null,

      upgradeModalVisible: false,
      settingsModalVisible: false,
      loginModalVisible: false,

      fullscreenImageModalVisible: false,
      fullscreenImage: null,
      

      selectedIndex: 0,

      taskLoading: false,
      taskStartTime: 0,
      percentLoading: 0

    }
  }


  componentDidMount() {
    // const replicate = new Replicate({token: 'f91c9225b6c13a56aabe938f560fa920fc809de1'});

    const auth = getAuth();
    let unsubscribe = null;

    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // const uid = user.uid;
        console.log("USER LOGGED IN", user);
        this.setState({
          user,
          initLoading: false,
          loginLoading: false,
          loginModalVisible: false,
          creditsLoading: true,
        });

        unsubscribe = onSnapshot(doc(db, "users", user.uid), (doc) => {
          if (doc.exists()) {
            console.log("Document data:", doc.data());
            const { credits, subscription } = doc.data();
            this.setState({ credits, subscription, creditsLoading: false });
          }
        });
      } else {
        console.log("USER LOGGED OUT");
        // User is signed out
        this.setState({
          user: null,
          initLoading: false,
          loginLoading: false,
          loginModalVisible: false,
          creditsLoading: false,
        });

        if (unsubscribe) {
          unsubscribe();
        }
      }
    });
  }


  setLoginModal(loginModalVisible) {
    this.setState({ loginModalVisible });
  }


  renderLoginModal() {
    return (
      <Modal onClose={() => this.setLoginModal(false)} open={this.state.loginModalVisible}>
        <Modal.Header>Sign in to start creating!</Modal.Header>
        <Modal.Content style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div style={{ marginBottom: 12 }} >By signing in, you agree to our Terms of Service</div>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
        </Modal.Content>
      </Modal>
    );
  }

  renderAccountButton() {
    if (this.state.user && !this.state.creditsLoading) {
      // derive account initials
      let { email, displayName } = this.state.user;

      let firstInitial = email.charAt(0).toUpperCase();
      let secondInitial = "";

      if (displayName) {
        displayName = displayName.trim();
        displayName.charAt(0);
        if (displayName.indexOf(" ") > 0) {
          secondInitial = displayName.charAt(displayName.indexOf(" ") + 1);
        }
      }

      const initials = firstInitial + secondInitial;

      // display user initials in a round bubble
      const initialBubble = (
        <div className="user-bubble">
          <div className="initials">{initials}</div>
        </div>
      );

      const options = [
        {
          key: "user",
          text: (
            <div className="header">
              <div>
                Signed in as <strong>{displayName}</strong>
              </div>
              <div>
                Credits remaining: <strong>{this.state.credits}</strong>
              </div>
            </div>
          ),
          disabled: true,
        },
      ];

      if (this.state.subscription) {
        options.push({
          key: "subscription",
          text: (
            <span className="stylisedItem">
              Subscribed to <strong>Pro {subscriptions[this.state.subscription]} 🎉</strong>
            </span>
          ),
        });
      } else {
        options.push({
          key: "upgrade",
          text: (
            <span className="stylisedItem">
              <strong>Upgrade ⭐️</strong>
            </span>
          ),
          onClick: () => this.setState({ upgradeModalVisible: true }),
        });
      }

      options.push(
        { key: "settings", text: "Settings", onClick: () => this.setState({ settingsModalVisible: true }) },
        { key: "help", text: "Help" },
        { key: "sign-out", text: "Sign Out", onClick: () => auth.signOut() }
      );

      return (
        <Dropdown
          className="sideMenu"
          trigger={initialBubble}
          options={options}
          wrapSelection={true}
          icon={null}
          direction="left"
        />
      );
    }

    return (
      <Button
        loading={this.state.initLoading || this.state.creditsLoading}
        disabled={this.state.initLoading || this.state.creditsLoading}
        onClick={() => this.setLoginModal(true)}
      >
        Login
      </Button>
    );
  }

  render() {

    const { selectedTab, initLoading, user } = this.state;




    if (!user) {
      if (initLoading) {
        return (
          <div style={{ margin: 80, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
              <Dimmer active>
                <Loader size='medium'  />
              </Dimmer>
          </div>
        )
      }
      return (
        <div style={{ margin: 80, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <div style={{ marginBottom: 12, color: 'white' }} >By signing in, you agree to our Terms of Service</div>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
        </div>
      )
    }


    let appHeader = (
      <div className="mainHeader">
        <div>
          <Image src={Logo} alt="AI FILM" size="small" />
        </div>
        <div className="menuTabBar">
          <div 
            className="menuTab"
            onClick={() => this.setState({ selectedTab: "CREATE" })}
            style={{ color: (selectedTab == "CREATE") ? "#E9CF51" : "rgba(255, 255, 255, 0.4)" }}>CREATE</div>
          <div 
            className="menuTab"
            onClick={() => this.setState({ selectedTab: "EDIT" })}
            style={{ color: (selectedTab == "EDIT") ? "#E9CF51" : "rgba(255, 255, 255, 0.4)" }}>EDIT</div>
          <div 
            className="menuTab"
            onClick={() => this.setState({ selectedTab: "REVIEW" })}
            style={{ color: (selectedTab == "REVIEW") ? "#E9CF51" : "rgba(255, 255, 255, 0.4)" }}>REVIEW</div>
        </div>
        <div className="menuOptions">
          <a href='mailto:support@backlot.studio' style={{ marginRight: 30 }}>Contact</a>
          {this.renderAccountButton()}
        </div>
      </div>
    );



    let tabContent = (
      <CreateTab 
          user={this.state.user}
          accountLoading={this.state.initLoading || this.state.creditsLoading}
          onEditImage={(image, job_id) => this.setState({ selectedTab: "EDIT", editImage: image, job_id })}
          onSetDefaultEditImage={(image, job_id) => this.setState({ editImage: image, job_id })}
          onFullscreenImage={(image) => this.setState({ fullscreenImage: image, fullscreenImageModalVisible: true })}
          displayLoginModal={() => this.setState({ loginModalVisible: true })} />
    )

    if (selectedTab == "EDIT") {
      tabContent = (
        <EditTab
          image={this.state.editImage}
          job_id={this.state.job_id}
          updateImage={(image) => this.setState({ editImage: image })}
          onFullscreenImage={(image) => this.setState({ fullscreenImage: image, fullscreenImageModalVisible: true })}
          functions={functions}
        />
      )
    } else if (selectedTab == "REVIEW") {
      tabContent = (
        <ReviewTab
          user={this.state.user}
          onEditImage={(image, job_id) => this.setState({ selectedTab: "EDIT", editImage: image, job_id })}
          onFullscreenImage={(image) => this.setState({ fullscreenImage: image, fullscreenImageModalVisible: true })}
        />
      )
    }

    return (
      <Segment className="mainSegment" basic>
        {appHeader}
        {/* <AppHeader/> */}

        {tabContent}

        <AppFooter />

        <UpgradeModal
          isVisible={this.state.upgradeModalVisible}
          closeModal={() => this.setState({ upgradeModalVisible: false })}
          subscription={this.state.subscription}
        />
        <SettingsModal
          isVisible={this.state.settingsModalVisible}
          closeModal={() => this.setState({ settingsModalVisible: false })}
        />

        <FullscreenImageModal
          image={this.state.fullscreenImage}
          isVisible={this.state.fullscreenImageModalVisible}
          closeModal={() => this.setState({ fullscreenImageModalVisible: false })}
        />

        {/* <LoginModal/> */}
        {/* {this.renderLoginModal()} */}
      </Segment>
    );
  }
}

export default Home;

/*

Top right corner:
Signup to start creating

Press create button:
Signup if not logged in






*/
