import React, { Component } from "react";
import { Modal, Input, Button, Image, Icon, Form, TextArea } from "semantic-ui-react";
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom';

import CharacterHits from './AlgoliaInfiniteSearch/CharacterHits';
import "./RefinementModal.css";

const searchClient = algoliasearch('PHMNSXEVLT', '99f100badfd34f3ea8f8415b9bc5d613');


class CharacterModal extends Component {


  constructor(props) {
    super(props);
    this.state = {
    }
  }


  render() {

    let {  isVisible, closeModal, onSelectCharacter } = this.props;

    return (
      <Modal className="refinement-modal" onClose={() => closeModal()} open={isVisible}>
        <Modal.Header>
          <h2>Cast your scene</h2>
          <h3>Pick a character whom you want to be seen within the frame</h3>
        </Modal.Header>
        <Modal.Content>
            <InstantSearch searchClient={searchClient} indexName="characters">
              <SearchBox />
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', height: '70vh', overflowY: 'scroll' }}>
                <CharacterHits onSelect={(hit) => onSelectCharacter(hit)} />
              </div>
            </InstantSearch>
        </Modal.Content>
      </Modal>
    )
  }
}

export default CharacterModal;