import React from "react";
import { Tab , Button} from "semantic-ui-react";
import "./Settings.css";

const TabSubscriptions = (props) => {
    const handleCancelSubscription = ()=>{
        
    }
  return (
    <Tab.Pane style={{ border: "100%", boxShadow: "100%" }} attached={false}>
      <div className="tab-pane">
        <Button onClick={handleCancelSubscription}> Cancel Subscription</Button>
      </div>
    </Tab.Pane>
  );
};
export default TabSubscriptions;
