
import "normalize.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";

import React, { Component } from 'react';
// import OpenAIAPI from 'openai-api-node';
import { Segment, Form, Button } from "semantic-ui-react";

// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import { getFunctions } from "firebase/functions";
// import { getFirestore } from "firebase/firestore";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyA4mgaIT2nq5UQbEQf7t--NcyrnnqfAApU",
//   authDomain: "filmdiffusion.firebaseapp.com",
//   projectId: "filmdiffusion",
//   storageBucket: "filmdiffusion.appspot.com",
//   messagingSenderId: "649715566050",
//   appId: "1:649715566050:web:89ef9199df86d5f1b6a5bb",
//   measurementId: "G-MP5HSH7M0J"
// };

// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// const functions = getFunctions(app);
// const db = getFirestore(app);
// const auth = getAuth(app);


// setup Firebase Auth


// const openai = new OpenAIAPI("sk-XWEpTg2eKrmUlERJO75uVsbzsPuPNwqUFc3DdOAt")

class Login extends Component {

  constructor(props){
    super(props);
    this.state = {
      email: '',
      password: '',
      loginLoading: false,
      loginError: null
    }
    
    this.setEmail = this.setEmail.bind(this);
    this.setPassword = this.setPassword.bind(this);
    this.signIn = this.signIn.bind(this);
  }

  componentDidMount(){
  }

  signIn() {

    const { email, password } = this.state;
    const auth = getAuth();

    this.setState({ loginLoading: true, loginError: null });

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in 
        // const user = userCredential.user;
        // ...
      })
      .catch((error) => {
        // const errorCode = error.code;
        const errorMessage = error.message;
        this.setState({ loginError: errorMessage });
      });
  }



  setEmail(event) {
    // console.log(event.target.value)
    this.setState({email: event.target.value});
  }

  setPassword(event) {
    // console.log(event.target.value)
    this.setState({password: event.target.value});
  }



  render() {

  return (
    <Segment placeholder>
            <Form>
            <Form.Input
                icon='user'
                iconPosition='left'
                label='Email'
                placeholder='Email'
                onChange={this.setEmail}
                value={this.state.email}
            />
            <Form.Input
                icon='lock'
                iconPosition='left'
                label='Password'
                type='password'
                placeholder='Password'
                onChange={this.setPassword}
                value={this.state.password}
            />

            <Button content='Login' onClick={this.signIn} primary />
            </Form>
        
        {(this.state.loginError) ? <p>{this.state.loginError}</p> : null}
      </Segment>
    );

  }
}


export default Login;

