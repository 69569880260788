import React, { useState } from "react";
import { Tab, Button, Form } from "semantic-ui-react";
import "./Settings.css";

const TabProfile = (props) => {
  const [formReadOnly, setFormReadOnly] = useState(false);

  const handleChangeProfile = () => {
    setFormReadOnly(true);
  };

  const handleSubmitChangeProfile = () => {
    setFormReadOnly(false);
  };

  return (
    <Tab.Pane style={{ border: "100%", boxShadow: "100%" }} attached={false}>
      <div className="tab-pane" >
        <Form>
          <Form.Field>
            <label>Display Name</label>
            <input placeholder={props.user_display_name} readOnly={formReadOnly} />
          </Form.Field>
          <Form.Field>
            <label>Password</label>
            <input type="password" placeholder="**********" readOnly={formReadOnly} />
          </Form.Field>
          {formReadOnly && (
            <Form.Field>
              <label>Repeat Password</label>
              <input type="password" placeholder="Repeat Password" />
            </Form.Field>
          )}
          {!formReadOnly && (
            <Button style={{}} onClick={handleChangeProfile}>
              Edit Display Name & Password
            </Button>
          )}
          {formReadOnly && <Button onClick={handleSubmitChangeProfile}>Save</Button>}
        </Form>
      </div>
    </Tab.Pane>
  );
};
export default TabProfile;
