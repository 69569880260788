import React, { Component } from "react";
import { Modal, Input, Button, Image, Icon, Form, TextArea, Loader, Dimmer } from "semantic-ui-react";
import "./ReviewTab.css";


import HoverImage from './HoverImage';


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions, connectFunctionsEmulator, httpsCallable } from "firebase/functions";
import { getFirestore, doc, onSnapshot, collection, query, where, getDocs, orderBy, limit } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";


// import compat firebase for Firebase Auth
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { json } from "react-router-dom";

import tagDictionary from "../tags_data/tags.json";
// import LoginModal from "./LoginModal";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA4mgaIT2nq5UQbEQf7t--NcyrnnqfAApU",
  authDomain: "filmdiffusion.firebaseapp.com",
  projectId: "filmdiffusion",
  storageBucket: "filmdiffusion.appspot.com",
  messagingSenderId: "649715566050",
  appId: "1:649715566050:web:89ef9199df86d5f1b6a5bb",
  measurementId: "G-MP5HSH7M0J",
};

// init firebase app
firebase.initializeApp(firebaseConfig);

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const functions = getFunctions(app);
const db = getFirestore(app);
const auth = getAuth(app);



class ReviewTab extends Component {


  constructor(props) {
    super(props);
    this.state = {
      jobs: [],
      isLoading: true
    }
  }


  async componentDidMount() {
    console.log("MOUNT")
    const { user } = this.props;

    this.setState({ isLoading: true })

    const q = query(collection(db, `users/${user.uid}/jobs`), orderBy("last_edited", "desc"), limit(10));

    const querySnapshot = await getDocs(q);
    let jobs = [];
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      jobs.push({job_id: doc.id, ...doc.data()})
    });
    this.setState({ jobs, isLoading: false })

    // db.collection(`users/${user.uid}/jobs`).
    // .get()
    // .then((querySnapshot) => {
      // let jobs = [];
      // querySnapshot.forEach((doc) => {
      //     jobs.push({job_id: doc.id, ...doc.data()})
      // });
      // this.setState({ jobs })
    // })
    // .catch((error) => {
    //     console.log("Error getting documents: ", error);
    // });
  }

  componentWillUnmount() {
    console.log("UNMOUNTING")
  }


  renderImageJob({ job_id, prompt, query, tags, shot_url, character, last_edited, jobs }) {

    
    let images = jobs.map(({ image_url }) => (
      <div style={{ width: "40vw", margin: 4 }}>
        <HoverImage 
          image={image_url} 
          onEditImage={() =>this.props.onEditImage(image_url, job_id)}
          onFullscreenImage={() => this.props.onFullscreenImage(image_url)}
        />
      </div>
    ));


    let tagsText = ""
    tags.forEach((tagID) => (
      tagsText += `${tagDictionary[tagID].text} | `
    ));
    tagsText = tagsText.slice(0, -3);


    let timeText = new Date(last_edited).toLocaleString()

    return(
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 40, marginBottom: 40 }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: "80vw", marginBottom: 12 }}>
          <div className="jobHeader">
            {query}
            
          </div>
          <div className="jobSubheader">
            {tagsText}
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start', flexWrap: 'wrap' }}>
          {images}
        </div>
      </div>
    )
  }


  render() {


    // <Segment size={"big"} placeholder style={{ margin: 12, border: 0, backgroundColor: 'transparent' }}>
    //   <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
    //     {this.renderImage(this.state.outputURL1, this.state.url1Loading && !this.state.taskLoading, this.state.url1Error)}
    //     {this.renderImage(this.state.outputURL2, this.state.url2Loading && !this.state.taskLoading, this.state.url2Error)}
    //   </div>
    //   <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
    //     {this.renderImage(this.state.outputURL3, this.state.url3Loading && !this.state.taskLoading, this.state.url3Error)}
    //     {this.renderImage(this.state.outputURL4, this.state.url4Loading && !this.state.taskLoading, this.state.url4Error)}
    //   </div>
    // </Segment>

    const { jobs, isLoading } = this.state;

    if (isLoading) {
      return (
        <Loader active size='large' />
      )
    }
    
    return (
        <div>
          { jobs.map((job) => this.renderImageJob(job)) }
        </div>
    );
  }
}

export default ReviewTab;