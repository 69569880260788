import React, { Component } from "react";
import { Modal } from "semantic-ui-react";
import { PricingTable, PricingSlot, PricingDetail } from "react-pricing-table";
import "./UpgradeModal.css";

// const paidPlans = [
//   {id: 'UPGRADE200', title: 'Pro Plan 200', num_credits: 200, price: 15, url: 'https://buy.stripe.com/14k2aucCTfNqdNu288'},
//   {id: 'UPGRADE500', title: 'Pro Plan 500', num_credits: 500, price: 25, url: 'https://buy.stripe.com/dR6g1kgT9bxaaBi7st'},
//   {id: 'UPGRADE1000', title: 'Pro Plan 1000', num_credits: 1000, price: 50, url: 'https://buy.stripe.com/4gwbL4dGXcBegZG9AC'}
// ]
// let myPrices = [
//   {
//     plan: "FREE",
//     price: "$0/month",
//     features: [
//       {
//         name: "20 credits monthly",
//         isEnabled: true
//       }
//     ]
//   },
//   {
//     plan: "PRO 200",
//     price: "$15/month",
//     features: [
//       {
//         name: "200 credits monthly",
//         isEnabled: true
//       }
//     ]
//   },

//   {
//     plan: "PRO 500",
//     price: "$25/month",
//     features: [
//       {
//         name: "500 credits monthly",
//         isEnabled: true
//       }
//     ]
//   },

//   {
//     plan: "PRO 1000",
//     price: "$50/month",
//     features: [
//       {
//         name: "1000 credits monthly",
//         isEnabled: true
//       }
//     ]
//   },

// ]

class UpgradeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.upgrade200 = this.upgrade200.bind(this);
    this.upgrade500 = this.upgrade500.bind(this);
    this.upgrade1000 = this.upgrade1000.bind(this);
    this.contactPricing = this.contactPricing.bind(this);
  }

  upgrade200() {
    window.location.href = "https://buy.stripe.com/14k2aucCTfNqdNu288";
  }

  upgrade500() {
    window.location.href = "https://buy.stripe.com/dR6g1kgT9bxaaBi7st";
  }

  upgrade1000() {
    window.location.href = "https://buy.stripe.com/4gwbL4dGXcBegZG9AC";
  }

  contactPricing() {
    window.location.href = "mailto:team@backlot.studio";
  }

  render() {
    const { isVisible, closeModal } = this.props;
    // ^ subscription
    return (
      <Modal onClose={() => closeModal()} open={isVisible}>
        <Modal.Header>
          <div>The perfect plan for any project</div>
        </Modal.Header>
        <Modal.Content>
          <PricingTable highlightColor="#822eff">
            <PricingSlot
              onClick={this.upgrade200}
              buttonText="UPGRADE"
              title="PRO 200"
              priceText="$15/month"
              buttonClass="purchasebutton"
            >
              <PricingDetail>
                {" "}
                <b>200</b> credits per month
              </PricingDetail>
            </PricingSlot>
            <PricingSlot
              highlighted
              onClick={this.upgrade500}
              buttonText="UPGRADE"
              title="PRO 500"
              priceText="$25/month"
              buttonClass="purchasebutton"
            >
              <PricingDetail>
                {" "}
                <b>500</b> credits per month
              </PricingDetail>
            </PricingSlot>
            <PricingSlot
              onClick={this.upgrade1000}
              buttonText="UPGRADE"
              title="PRO 1000"
              priceText="$50/month"
              buttonClass="purchasebutton"
            >
              <PricingDetail>
                {" "}
                <b>1000</b> credits per month
              </PricingDetail>
            </PricingSlot>
            <PricingSlot
              onClick={this.contactPricing}
              buttonText="CONTACT US"
              title="ENTERPRISE"
              priceText="Custom Pricing"
              buttonClass="purchasebutton"
            >
              <PricingDetail> Contact for a quote</PricingDetail>
            </PricingSlot>
          </PricingTable>
        </Modal.Content>
      </Modal>
    );
  }

  //   <section className="pricing py-5" style={{ width: "100%", height: "100%", display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 0 }}>
  //   <div style={{ display: 'flex', width: "80%", flexDirection: 'row', justifyContent: 'space-between'}}>
  //     {
  //       myPrices.map( (obj) =>{return<Card data={obj}></Card>})   // store data in myPrices map to card
  //     }
  // </div>
  // </section>

  // <Button style={{ fontFamily: "Roboto", borderRadius: 0 }} content={'Upgrade 200'} onClick={this.upgrade200} secondary />
  // <Button style={{ fontFamily: "Roboto", borderRadius: 0 }} content={'Upgrade 500'} onClick={this.upgrade500} secondary />
  // <Button style={{ fontFamily: "Roboto", borderRadius: 0 }} content={'Upgrade 1000'} onClick={this.upgrade1000} secondary />
}

export default UpgradeModal;
