import { Link } from "react-router-dom";

import React, { Component } from "react";
// import OpenAIAPI from 'openai-api-node';
import { Button, Input, Dimmer, Segment, Image, Progress, Modal, Icon, Dropdown, Placeholder } from "semantic-ui-react";

import Toggle from "react-toggle";
import "react-toggle/style.css";

// import { WithContext as ReactTags } from "react-tag-input";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions, connectFunctionsEmulator, httpsCallable } from "firebase/functions";
import { getFirestore, doc, onSnapshot, collection, query, where, getDocs, orderBy, limit } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";


import CharacterModal from './CharacterModal';
import ShotTypeModal from './ShotTypeModal';
import HoverImage from './HoverImage';
import TagButton from './TagButton';

import RetryIcon from "../assets/retry.svg";

import './Home.css';

import tagDictionary from "../tags_data/tags.json";

// import compat firebase for Firebase Auth
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
// import LoginModal from "./LoginModal";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA4mgaIT2nq5UQbEQf7t--NcyrnnqfAApU",
  authDomain: "filmdiffusion.firebaseapp.com",
  projectId: "filmdiffusion",
  storageBucket: "filmdiffusion.appspot.com",
  messagingSenderId: "649715566050",
  appId: "1:649715566050:web:89ef9199df86d5f1b6a5bb",
  measurementId: "G-MP5HSH7M0J",
};

// init firebase app
firebase.initializeApp(firebaseConfig);

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const functions = getFunctions(app);
const db = getFirestore(app);
const auth = getAuth(app);



// connectFunctionsEmulator(functions, "127.0.0.1", 5001);



// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
  // signInSuccessUrl: '/signedIn',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
  ],

  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};





// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const tagTypes = [
  { id: "LOCATION", text: "Location" },
  { id: "COMPOSITION", text: "Composition" },
  { id: "COLOR_TONES", text: "Visual Style" },
//  { id: "ACTION", text: "Action" },
  { id: "CHARACTER", text: "Character" },
  // { id: "COSTUME", text: "Costume" },
  { id: "LIGHTING", text: "Lighting" }
];

// { id: "COLOR_TONES", text: "Color Tones ⭐️" },
// { id: "FILMMAKER", text: "Filmmaker" },
// { id: "CAMERA", text: "Camera" },
// { id: "LIGHTING", text: "Lighting" },
// { id: "LENS", text: "Lens" },
// // { id: "SHOT_TYPE", text: "Shot Type" },
// { id: "SPECIAL_EFFECTS", text: "Special Effects" },
// { id: "TIME_OF_DAY", text: "Time of Day" },
// { id: "WEATHER", text: "Weather" },
// { id: "GENRE", text: "Genre" },
// { id: "FILM", text: "Film" },
// { id: "TIME_PERIOD", text: "Time Period" }



const AUTHENTICATION_FAILURE = "Authentication failure. Please contact team@backlot.studio if this problem persists.";
const CONNECTION_FAILURE = "Failed to connect to the server. Please try again.";
const REPLICATE_NO_RESPONSE = "Server is busy. Please try again.";
const REPLICATE_ERROR = "Server error. Please try again.";
const FIRESTORE_ERROR = "Database error. Please try again and contact team@backlot.studio if this problem persists";
const SERVER_TIMEOUT = "Server timed out. Please try again."; // if Replicate callback doesn't finish in the specified window
const NO_CREDITS = "You have made too many requests. Please contact team@backlot.studio for more credits.";

const customModes = [
  {
    key: 'none',
    text: 'Film',
    value: null
  },
  {
    key: 'storyboard',
    text: 'Storyboard',
    value: 'storyboard'
  },
  {
    key: 'concept',
    text: 'Concept Art',
    value: 'concept'
  }
]


const customStyleOptions = [
  {
    key: "none",
    text: "None",
    value: null,
  },
  {
    key: "avatar",
    text: "Avatar",
    value: "avatar",
  },
  {
    key: "simpsons",
    text: "The Simpsons",
    value: "simpsons",
  },
];

class CreateTab extends Component {
  constructor(props) {
    super(props);
    this.state = {

      characterModalVisible: false,
      character: null,

      shotTypeModalVisible: false,
      shotType: null,

      actionModalVisible: false,

      query: "",
      tags: [],
      custom_tags: [],
      custom_mode: null,
      custom_style: null,
      job_id: null,

      lastQuery: "",
      lastTags: [],
      lastCustomTags: [],
      lastCustomMode: null,
      lastCustomStyle: null,
      lastCharacter: null,
      lastShotType: null,

      modalCategorySelected: null, // should be null
      modalCategoryTags: [], // should be empty

      customTagQuery: "",
      customTagModalOpen: false,

      outputImages: [null, null, null, null],

      selectedIndex: 0,

      taskLoading: false,
      taskStartTime: 0,
      percentLoading: 0

    }

    this.submitQuery = this.submitQuery.bind(this);
    this.changeQuery = this.changeQuery.bind(this);
    this.changeCustomTagQuery = this.changeCustomTagQuery.bind(this);
  }

  // outputImages: [
  //   null, null, null, null
  // ],


  async loadInitialImages() {
    const { user } = this.props;
    if (!user) { return; }
    const q = query(collection(db, `users/${user.uid}/jobs`), orderBy("last_edited", "desc"), limit(1));
    const querySnapshot = await getDocs(q);
    let outputImages = [
      "default_placeholders/1.png",
      "default_placeholders/2.png",
      "default_placeholders/3.png",
      "default_placeholders/4.png"
    ];
    let job_id = "";

    let doc = querySnapshot.docs[0]
    if (doc && doc.exists() && doc.data().jobs) {
      outputImages = []
      doc.data().jobs.forEach(job => {
        outputImages.push(job.image_url)
      })
      job_id = doc.id;
    }


    this.props.onSetDefaultEditImage(outputImages[this.state.selectedIndex], job_id)
    this.setState({ outputImages, job_id });
  }

   componentDidMount() {

    const interval = setInterval(() => {
      if (this.state.taskLoading && this.state.taskStartTime != 0) {
        const percentLoading = Math.min(Math.round((100 * (Date.now() - this.state.taskStartTime)) / 16000), 85);
        console.log(percentLoading);
        this.setState({ percentLoading });
      }
    }, 500);

    this.loadInitialImages()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user != this.props.user) {
      this.loadInitialImages()
    }
  }



  translateErrorCode(errorCode) {
    switch (errorCode) {
      case "AUTHENTICATION_FAILURE":
        return AUTHENTICATION_FAILURE;
      case "CONNECTION_FAILURE":
        return CONNECTION_FAILURE;
      case "REPLICATE_NO_RESPONSE":
        return REPLICATE_NO_RESPONSE;
      case "REPLICATE_ERROR":
        return REPLICATE_ERROR;
      case "FIRESTORE_ERROR":
        return FIRESTORE_ERROR;
      case "SERVER_TIMEOUT":
        return SERVER_TIMEOUT;
      case "NO_CREDITS":
        return NO_CREDITS;
      default:
        return CONNECTION_FAILURE;
    }
  }






  submitServer({ job_id, shot_url, query, tags, custom_tags, prompt_override, negprompt_override, character, denoising_strength }) {


    console.log(query, shot_url, tags, custom_tags, character, denoising_strength)


    let prefilmServer = httpsCallable(functions, 'prefilmServer');
    prefilmServer({
        job_id,
        query,
        tags,
        custom_tags,
        character: (character ? character.prompt : null),
        prompt_override,
        negprompt_override,
        shot_url,
        width: 768,
        height: 384,
        denoising_strength,
        steps: 40,
        cfg_scale: 7,
        restore_faces: true
      })
      .then((result) => {
        console.log("Output", result, Date.now());

        console.log(result.data.url);

        /*
        const { output, error_code, image } = result.data;

        // if an image is returned
        if (image) {
          // replace the first instance of null in outputOrder with the outputURL
          const outputIndex = this.state.outputImages.indexOf(null);
          let outputTemp = [...this.state.outputImages];
          outputTemp[outputIndex] = { image, status: "success" };

          // reorder outputTemp so that success images are first
          outputTemp.sort((a, b) => {
            if (!a || !b) return 0;

            if (a.status === "success" && b.status === "error") {
              return -1;
            } else if (a.status === "error" && b.status === "success") {
              return 1;
            } else {
              return 0;
            }
          });

          // if this is the first result back, set selectedIndex to the first entry
          let stateParams = { outputImages: outputTemp, taskLoading: false };
          if (this.state.outputImages.every((element) => element === null)) {
            stateParams.selectedIndex = 0;
          }
          this.setState(stateParams);
          return;
        }

        if (error_code) {
          // replace the first instance of null in outputOrder with the outputURL
          const outputIndex = this.state.outputImages.indexOf(null);
          let outputTemp = [...this.state.outputImages];
          outputTemp[outputIndex] = { errorMessage: this.translateErrorCode(error_code), status: "error" };

          // if the error is a specific type, set the overall task error
          this.setState({ outputImages: outputTemp, taskLoading: false });
          return;
        }

        */

        // otherwise set the output
        // const outputURL = output[0];
        // const outputOrder = this.state.outputOrder.push(i);
        // this.setState({ ["outputURL" + i]: outputURL, outputOrder, ["url" + i + "Loading"]: false, taskLoading: false });

        const outputURL = result.data.url; //output[0];

        // replace the first instance of null in outputOrder with the outputURL
        const outputIndex = this.state.outputImages.indexOf(null);
        let outputTemp = [...this.state.outputImages];
        outputTemp[outputIndex] = outputURL;

        // // reorder outputTemp so that success images are first
        // outputTemp.sort((a, b) => {
        //   if (!a || !b) return 0;

        //   if (a.status === "success" && b.status === "error") {
        //     return -1;
        //   } else if (a.status === "error" && b.status === "success") {
        //     return 1;
        //   } else {
        //     return 0;
        //   }
        // });

        // if this is the first result back, set selectedIndex to the first entry
        let stateParams = { job_id, outputImages: outputTemp, taskLoading: false };
        if (this.state.outputImages.every((element) => element === null)) {
          stateParams.selectedIndex = 0;
        }
        this.setState(stateParams);
      })
      .catch((error) => {
        console.log(error);
        // this.setState({ ["url" + i + "Error"]: CONNECTION_FAILURE, ["url" + i + "Loading"]: false, taskLoading: false });
      });
  }


  async submitQuery() {


    const { user } = this.props;

    const { query, tags, custom_tags, custom_mode, custom_style, character, shotType } = this.state;

    // if no user logged in, show login modal in order for them to submit query
    if (!user) {
      this.props.displayLoginModal();
      return;
    }

    // set the state to loading
    this.setState({
      taskLoading: true,
      percentLoading: 0,
      // taskStartTime: 0,
      taskStartTime: Date.now(),

      outputImages: [null, null, null, null],
      selectedIndex: 0,
      lastQuery: query,
      lastTags: [ ...tags ],
      lastCustomTags: [ ...custom_tags ],
      lastCustomMode: custom_mode,
      lastCustomStyle: custom_style,
      lastCharacter: character,
      lastShotType: shotType,
    })

    // different levels of faithfulness to the original
    let denoisingStrengths = [0.8, 0.8, 0.8, 0.9] // [0.6, 0.7, 0.9, 0.9]
    let job_id = `${Date.now()}`;
    // run sendReplicate, iterating 4 times
    let shot_url = shotType ? shotType.image : null;
    let prompt_override = null;
    let negprompt_override = null;

    let composition = null;
    let color_tone = null;

    let final_tags = []
    tags.forEach((tag, i) => {

      // override denoising strengths if a location requires it
      if (tagDictionary[tag].denoising_override) {
        denoisingStrengths = tagDictionary[tag].denoising_override;
      }

      if (tagDictionary[tag].type == "COMPOSITION") {
        composition = tagDictionary[tag].id
      } else if (tagDictionary[tag].type == "COLOR_TONES") {
        color_tone = tagDictionary[tag].id
      }

      if (tagDictionary[tag].shot_url) {
        shot_url = tagDictionary[tag].shot_url;
      }
      if (tagDictionary[tag].negprompt_override) {
        negprompt_override = tagDictionary[tag].negprompt_override;
      }
      if (tagDictionary[tag].prompt_override) {
        prompt_override = tagDictionary[tag].prompt_override;
      } else {
        // only add tag if NO prompt override
        final_tags.push(tag)
      }
    })
    if (composition) {
      console.log("GENERATED URL");
      let color_tone_t = color_tone ? color_tone : "neutral";
      shot_url=`https://storage.googleapis.com/filmdiffusion_public/image_types_v1/${color_tone_t}/${composition}.png`
    }
    
    
    for (let i = 1; i < 5; i++) {
      this.submitServer({ job_id, shot_url, query, tags: final_tags, custom_tags, prompt_override, negprompt_override, character, denoising_strength: denoisingStrengths[i-1] });
      await new Promise(r => setTimeout(r, 1000));
    }
  }

  retryQuery(index) {
    const { job_id, lastQuery, lastTags, lastCustomTags, lastCustomMode, lastCustomStyle, lastCharacter, lastShotType} = this.state;

    // remove the object at the index from outputImages
    let outputTemp = [...this.state.outputImages];
    outputTemp[index] = null;
    this.setState({ outputImages: outputTemp });

    let shot_url = lastShotType.image;

    this.submitServer({ 
      job_id,
      shot_url,
      query: lastQuery,
      tags: lastTags, 
      custom_tags: lastCustomTags, 
      custom_mode: lastCustomMode, 
      custom_style: lastCustomStyle, 
      character: lastCharacter, 
      denoising_strength: 0.8
    });
  }


  changeQuery(event) {
    // console.log(event.target.value)
    this.setState({ query: event.target.value });
  }

  changeCustomTagQuery(event) {
    // console.log(event.target.value)
    this.setState({ customTagQuery: event.target.value });
  }

  setTagModal(modalCategorySelected) {
    if (!modalCategorySelected) {
      this.setState({ modalCategorySelected: null });
      return;
    }

    // find all tags in Tag Dictionary with the selected type
    const modalCategoryTags = Object.keys(tagDictionary).filter((tag) => {
      return tagDictionary[tag].type === modalCategorySelected.id;
    });
    this.setState({ modalCategorySelected, modalCategoryTags });
  }


  addTag(id) {
    // add new tag & deduplicate tags in array
    let { tags } = this.state;

    // remove any tags that also location or framing
    if (tagDictionary[id].type == "LOCATION") {
      tags = tags.filter((tag) => {
        return tagDictionary[tag].type != "LOCATION";
      })
    } else if (tagDictionary[id].type == "COMPOSITION") {
      tags = tags.filter((tag) => {
        return tagDictionary[tag].type != "COMPOSITION";
      })
    }

    tags = [...new Set([...tags, id])];

    this.setState({ tags, modalCategorySelected: null });
    // close the modal upon adding a tag
  }

  removeTag(id) {
    this.setState({ tags: this.state.tags.filter((tagID, index) => tagID !== id) });
  }

  addCustomTag(text, type) {
    // add new tag & deduplicate tags in array
    const custom_tags = [...new Set([...this.state.custom_tags, { text, type }])];
    this.setState({ custom_tags, modalCategorySelected: null, customTagModalOpen: false });
    // close the modal upon adding a tag
  }

  removeCustomTag(index) {
    this.setState({ custom_tags: this.state.custom_tags.filter((tag, i) => i !== index) });
  }


  renderThumbnailImage(image, index) {

    // values for each size
    // const width = (is_storyboard) ? "9vw" : "15.75vw"
    // const height = "9vw"
    // const maxWidth = (is_storyboard) ? 140 : 245;
    // const maxHeight = 140;

    const width = "18vw";
    const height = "7.5vw";
    const maxWidth = 216;
    const maxHeight = 90;

    let loading = !image && !this.state.taskLoading;
    let error = image == "ERROR";
    // let errorMessage = "Image could not be loaded";

    // let imageContent = null;
    // if (imageResult) {
    //   if (imageResult.image) {
    //     imageContent = `data:image/jpeg;base64,${imageResult.image}`;
    //     console.log(imageResult.image.offsetHeight);
    //   } else if (imageResult.url) {
    //     imageContent = imageResult.url;
    //   }
    // }

    if (error) {
      return (
        <Segment>
          <Image
            src={RetryIcon}
            alt="retry button"
            style={{ width: 40, height: 40, padding: 6, borderRadius: 4 }}
            onClick={() => this.retryQuery(index)}
          />
        </Segment>
      );
    }

    // <p style={{ fontSize: 12, marginTop: 10, width: "90%", color: "white", textAlign: "center" }}>{imageResult.errorMessage}</p>

    // <Icon name='warning sign' size='large' color='orange' />


    return (
      <Segment style={{ width, height, maxHeight, maxWidth }} >
        <Dimmer active={loading}>
          <Placeholder style={{ width, height, maxHeight, maxWidth }} inverted>
            <Placeholder.Image />
          </Placeholder>
        </Dimmer>
        {image ? <Image src={image} style={{ objectFit: "contain" }} /> : null}
        <div 
          className="hoverOverlay"
          onClick={() => {
            this.props.onSetDefaultEditImage(this.state.outputImages[index], this.state.job_id)
            this.setState({ selectedIndex: index })
          }}
          >
          <Image
              className="retryButton"
              src={RetryIcon} 
              alt="retry button" 
              onClick={() => this.retryQuery(index)} 
            />
        </div>
      </Segment>
    );
  }

  handleInputKeyPress(e) {
    if (e.key === "Enter") {
      this.submitQuery();
    }
  }

  renderTagImage(selectedCategoryID, tagID) {
    let imageComponent = (
      <div className="imageContainer">
        <div className="imageName">{tagDictionary[tagID].text}</div>
        <Image
          src={tagDictionary[tagID].thumbnail}
          onError={(event) => (event.target.style.display = "none")}
          style={{ height: 200 }}
        />
      </div>
    );

    return imageComponent;
    // imageComponent = (
    //   <div style={{ backgroundColor: '#fefefe', width: 385, height: 220 }}>

    //   </div>
    // )
  }

  renderTagModal() {
    let selectedCategoryID = this.state.modalCategorySelected ? this.state.modalCategorySelected.id : "";
    let selectedCategoryText = this.state.modalCategorySelected ? this.state.modalCategorySelected.text : "";

    return (
      <Modal
        className="tagModal"
        onClose={() => this.setTagModal(null)}
        open={this.state.modalCategorySelected != null}
      >
        <Modal.Header className="tagModalHeader">
          <div>+ {selectedCategoryText.toUpperCase()}</div>
          <Button
            content={"Try Custom Option"}
            onClick={() => this.setState({ customTagModalOpen: true, customTagQuery: "" })}
            secondary
          />
        </Modal.Header>
        <Modal.Content scrolling>
          {this.state.modalCategoryTags.map((tagID) => (
            <div className="tagImageOption" onClick={() => this.addTag(tagID)}>
              {this.renderTagImage(selectedCategoryID, tagID)}
              <div className="imageLabel">{tagDictionary[tagID].text}</div>
            </div>
          ))}
        </Modal.Content>

        <Modal
          onClose={() => this.setState({ customTagModalOpen: false })}
          open={this.state.customTagModalOpen}
          size="small"
        >
          <Modal.Header>Custom {selectedCategoryText}</Modal.Header>
          <Modal.Content>
            <Input
              onChange={this.changeCustomTagQuery}
              value={this.state.customTagQuery}
              style={{ marginTop: 20, marginBottom: 8, width: "80%", maxWidth: 600 }}
              placeholder="Type your custom option..."
              size="big"
            />
          </Modal.Content>

          <Modal.Actions>
            <Button
              icon="plus"
              content="Add"
              onClick={() => this.addCustomTag(this.state.customTagQuery, this.state.modalCategorySelected.id)}
            />
          </Modal.Actions>
        </Modal>
      </Modal>
    );
  }



  renderActionModal() {
    return (
      <Modal
        className="tagModal"
        style={{ height: 260 }}
        onClose={() => this.setState({ actionModalVisible: false })}
        open={this.state.actionModalVisible}
      >
        <Modal.Header className="tagModalHeader">
          <div>+ ACTION</div>
        </Modal.Header>
        <Modal.Content style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'transparent', alignItems: 'center' }}>
          <div className="searchBar">
              <Input
                style={{ width: "65vw" }}
                onChange={this.changeQuery}
                value={this.state.query}
                placeholder="climbing up a boulder"
                onKeyPress={this.handleInputKeyPress.bind(this)}
                size="big"
              />
            </div>
              <Button
                style={{ width: "120px", margin: 32 }}
                onClick={() => this.setState({ actionModalVisible: false })}
                color="gray"
                size="big"
              >
                OK
              </Button>
        </Modal.Content>
      </Modal>
    );
  }







  renderImageGrid() {

    // old GRID final output
    // let finalOutput = (
    //     <Segment size={"big"} placeholder style={{ margin: 12, border: 0, backgroundColor: 'transparent' }}>
    //       <Dimmer active={this.state.taskLoading} blurring style={{ width: "100%", height: "100%" }}>
    //           <div>Creating...</div>
    //           <div style={{ marginTop: 8, marginBottom: 16, fontSize: 24 }}>{this.state.percentLoading}%</div>
    //           <Progress percent={this.state.percentLoading} style={{ width: 500, backgroundColor: '#444444' }} size='tiny' indicating />
    //       </Dimmer>
    //       <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
    //         {this.renderImage(this.state.outputURL1, this.state.url1Loading && !this.state.taskLoading, this.state.url1Error)}
    //         {this.renderImage(this.state.outputURL2, this.state.url2Loading && !this.state.taskLoading, this.state.url2Error)}
    //       </div>
    //       <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
    //         {this.renderImage(this.state.outputURL3, this.state.url3Loading && !this.state.taskLoading, this.state.url3Error)}
    //         {this.renderImage(this.state.outputURL4, this.state.url4Loading && !this.state.taskLoading, this.state.url4Error)}
    //       </div>
    //     </Segment>
    //   );

    let finalOutput = null;

    if (!this.props.user) {
      finalOutput = (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
          <div style={{ fontSize: 24, margin: 40, color: 'white' }}>Please log in to use AI Film</div>
        </div>
      );
    } else if (this.state.outputImages) {
      finalOutput = (
        <Segment className="imageResults" size={"big"} placeholder>
          <Dimmer active={this.state.taskLoading} blurring="true">
            <div>Creating...</div>
            <div className="text">{this.state.percentLoading}%</div>
            <Progress percent={this.state.percentLoading} size="tiny" indicating />
          </Dimmer>
          <div className="imagesContainer">
            <div className="mainImage">
              <HoverImage 
                image={this.state.outputImages[this.state.selectedIndex]} 
                displayLoader={!this.state.taskLoading && !this.state.outputImages[this.state.selectedIndex]}
                onEditImage={(image) =>this.props.onEditImage(image, this.state.job_id)}
                onFullscreenImage={(image) => this.props.onFullscreenImage(image)}
                onDeleteImage={() => this.retryQuery(this.state.selectedIndex)}
              />
            </div>
            <div className="sideImage">
              {this.renderThumbnailImage(this.state.outputImages[0], 0)}
              {this.renderThumbnailImage(this.state.outputImages[1], 1)}
              {this.renderThumbnailImage(this.state.outputImages[2], 2)}
              {this.renderThumbnailImage(this.state.outputImages[3], 3)}
            </div>
          </div>
        </Segment>
      );
    }
    return finalOutput;
  }

  renderCreationInput() {

    const { taskLoading, query, shotType, character, tags, custom_tags } = this.state;
    const { accountLoading } = this.props;

    return (
      <div className="imageProperties">
        <div className="search">
          

          <div style={{
            color:'#ffffff60',
            fontSize: 18,
            marginBottom: 12
          }}>Build your shot...</div>
          <Input
                onChange={this.changeQuery}
                value={this.state.query}
                placeholder="Describe your action..."
                onKeyPress={this.handleInputKeyPress.bind(this)}
                size="big"
                style={{ marginBottom: 10 }}
              />
          <div className="searchTags">
            {shotType ? (
              <TagButton
                type={"image"}
                image={shotType.thumbnail}
                altText={shotType.text}
                aspectType={'wide'}
                onRemove={() => this.setState({ shotType: null })}
              />
            ) : null }

            {character ? (
                <TagButton 
                  type={"image"}
                  image={character.thumbnail}
                  altText={character.text}
                  aspectType={'square'}
                  onRemove={() => this.setState({ character: null })}
                />
            ) : null}

            {query ? (
                <TagButton 
                  type={"text"}
                  text={query}
                  onRemove={() => this.setState({ query: "" })}
                />
            ) : null}

          
            {tags.map((tagID) => {
              let showImage = ((tagDictionary[tagID].type == 'COMPOSITION') || (tagDictionary[tagID].type == 'LOCATION'));
              return (
                <TagButton 
                  type={showImage ? "image" : "text"}
                  aspectType={showImage ? 'wide' : null}
                  image={tagDictionary[tagID].thumbnail}
                  text={tagDictionary[tagID].text}
                  onRemove={() => this.removeTag(tagID)}
                />
              )
            })}


            {/* {tags.map((tagID) => (
              <div className="tag" onClick={() => this.removeTag(tagID)}>
                {tagDictionary[tagID].text}
                <Icon name="close" size="small" color="white" />
              </div>
            ))} */}

            {custom_tags.map((custom_tag, index) => (
              <div className="tag" onClick={() => this.removeCustomTag(index)}>
                {custom_tag.type} - {custom_tag.text}
                <Icon className="close" size="small" color="white" />
              </div>
            ))}
          </div>
        </div>

        <div className="properties">
          {/* <div className="tag-button" onClick={() => this.setState({ shotTypeModalVisible: true })}>
              + INSPIRATION ⭐️
          </div> */}

          {tagTypes.map((tagType) => (
            <div className="tag-button" onClick={() => {
                switch(tagType.id) {
                  case 'CHARACTER':
                    this.setState({ characterModalVisible: true })
                    break;
                  case 'ACTION':
                    this.setState({ actionModalVisible: true })
                    break;
                  default:
                    this.setTagModal(tagType)
                    break;
                }
              }}>
              + {tagType.text.toUpperCase()}
            </div>
          ))}
          
        </div>
        <Button
              style={{ flex: 1, width: 600, marginTop: 16, marginLeft: 12, marginRight: 12, borderRadius: 0, color: 'black' }}
              onClick={this.submitQuery}
              disabled={taskLoading || accountLoading }
              color="yellow"
              size="big"
            >
              CREATE
            </Button>
      </div>
    )
  }





  render() {


    return (
      <div>
        {this.renderImageGrid()}
        {this.renderCreationInput()}
        {this.renderTagModal()}

        {/* {this.renderActionModal()} */}
        <CharacterModal
          isVisible={this.state.characterModalVisible}
          closeModal={() => this.setState({ characterModalVisible: false })}
          onSelectCharacter={(character) => this.setState({ characterModalVisible: false, character })}
        />
        <ShotTypeModal
          isVisible={this.state.shotTypeModalVisible}
          closeModal={() => this.setState({ shotTypeModalVisible: false })}
          onSelectShotType={(shotType) => this.setState({ shotTypeModalVisible: false, shotType })}
        />
      </div>
    );
  }
}

export default CreateTab;
