import React, { Component } from "react";
import { Modal, Input, Button, Image, Icon, Form, TextArea, Loader, Dimmer, Dropdown } from "semantic-ui-react";
import { ReactSketchCanvas } from "react-sketch-canvas";
import { httpsCallable } from "firebase/functions";
import "./RefinementModal.css";

const presetOptions = {
  "CHARACTER_1": { text: "Character 1", prompt: "Paul Giamatti from the film \"Cinderella Man\"", type: "CHARACTER", image: "https://storage.googleapis.com/filmdiffusion_public/characters_golden_gate/Paul%20Giamatti%20from%20the%20film%20%22Cinderella%20Man%22.jpeg"},
  "CHARACTER_2": { text: "Character 2", prompt: "Daniel Craig", type: "CHARACTER", image: "https://storage.googleapis.com/filmdiffusion_public/characters_golden_gate/Daniel%20Craig.jpeg"},
  "CHARACTER_3": { text: "Character 3", prompt: "Liam Aiken (child)", type: "CHARACTER", image: "https://storage.googleapis.com/filmdiffusion_public/characters_golden_gate/Liam%20Aiken%20(child).jpeg"},
  "CHARACTER_4": { text: "Character 4", prompt: "Tyler Hoechlin (child)", type: "CHARACTER", image: "https://storage.googleapis.com/filmdiffusion_public/characters_golden_gate/Tyler%20Hoechlin%20(child).jpeg"},
  "CHARACTER_5": { text: "Character 5", prompt: "Paul Newman", type: "CHARACTER", image: "https://storage.googleapis.com/filmdiffusion_public/characters_golden_gate/Paul%20Newman.jpeg"},
  "CHARACTER_6": { text: "Character 6", prompt: "Ian Barford", type: "CHARACTER", image: "https://storage.googleapis.com/filmdiffusion_public/characters_golden_gate/Ian%20Barford.jpeg"},
  "CHARACTER_7": { text: "Character 7", prompt: "Renee Zellwegger", type: "CHARACTER", image: "https://storage.googleapis.com/filmdiffusion_public/characters_golden_gate/Renee%20Zellwegger.jpeg"},
  "CHARACTER_8": { text: "Character 8", prompt: "Stanley Tucci", type: "CHARACTER", image: "https://storage.googleapis.com/filmdiffusion_public/characters_golden_gate/Stanley%20Tucci.jpeg"},
  "CHARACTER_9": { text: "Character 9", prompt: "Ciarán Hinds", type: "CHARACTER", image: "https://storage.googleapis.com/filmdiffusion_public/characters_golden_gate/Ciara%CC%81n%20Hinds.jpeg"},
  "CHARACTER_10": { text: "Character 10", prompt: "1930s Construction Worker", type: "CHARACTER", image: "https://storage.googleapis.com/filmdiffusion_public/characters_golden_gate/1930s%20Construction%20Worker.jpeg"},
  "CHARACTER_11": { text: "Character 11", prompt: "Paddy Considine", type: "CHARACTER", image: "https://storage.googleapis.com/filmdiffusion_public/characters_golden_gate/Paddy%20Considine.jpeg"},
  "CHARACTER_12": { text: "Character 12", prompt: "Renee Zellwegger from the film \"Cinderella Man\"", type: "CHARACTER", image: "https://storage.googleapis.com/filmdiffusion_public/characters_golden_gate/Renee%20Zellwegger%20from%20the%20film%20%22Cinderella%20Man%22.jpeg"},
  "CHARACTER_13": { text: "Character 13", prompt: "Paul Giamatti", type: "CHARACTER", image: "https://storage.googleapis.com/filmdiffusion_public/characters_golden_gate/Paul%20Giamatti.jpeg"},
  "CHARACTER_14": { text: "Character 14", prompt: "Paul Newman from the film \"Road to Perdition\"", type: "CHARACTER", image: "https://storage.googleapis.com/filmdiffusion_public/characters_golden_gate/Paul%20Newman%20from%20the%20film%20%22Road%20to%20Perdition%22.jpeg"},
  "CHARACTER_15": { text: "Character 15", prompt: "Paddy Considine from the film \"Cinderella Man\"", type: "CHARACTER", image: "https://storage.googleapis.com/filmdiffusion_public/characters_golden_gate/Paddy%20Considine%20from%20the%20film%20%22Cinderella%20Man%22.jpeg"},
  "HARDHAT": { text: "Hard Hat", prompt: "worn antique museum ((dark brown)) leather 1919 short safety cap", negprompt: "metal, plastic, military, army, orange, yellow, white, light, smooth, shiny, hard-surface, text", type: "OBJECT" },
  "DIVINGSUIT": { text: "Diving Suit", prompt: "antique metal Deep Sea Diving Suit", type: "OBJECT" },
  "OTHER": { text: "Custom", prompt: null, type: "CUSTOM"}
} 

class EditTab extends Component {


  constructor(props) {
    super(props);
    this.state = {
      image: null,
      imageWidth: 1920,
      imageHeight: 1080,

      prompt: "",
      negprompt: "",
      preset: 'OTHER', // HARDHAT, DIVINGSUIT, null
      eraseMode: false,
      strokeWidth: 24,
      mask: null,

      //replacementImage: null
      isLoading: false
    }

    this.canvas = React.createRef();
    this.onImageLoad = this.onImageLoad.bind(this);
    this.runInpainting = this.runInpainting.bind(this);


    this.resetCanvas = this.resetCanvas.bind(this);
    this.undoCanvas = this.undoCanvas.bind(this);
    this.redoCanvas = this.redoCanvas.bind(this);
  }


  componentDidMount() {
    this.setState({ image: this.props.image })
  }

  // when props change
  componentDidUpdate(prevProps) {
    if (prevProps.image !== this.props.image) {
      console.log("image changed")
      this.setState({ image: this.props.image })
    }
  }

  // find the dimensions of the original image
  onImageLoad({target:img}) {
    this.setState({ 
      imageHeight: img.naturalHeight,
      imageWidth: img.naturalWidth
    });
  }

  resetCanvas() {
    if (!this.canvas.current) { return; } // catch error when canvas is not loaded yet
    this.canvas.current.clearCanvas();
  }

  undoCanvas() {
    if (!this.canvas.current) { return; } // catch error when canvas is not loaded yet
    this.canvas.current.undo();
  }

  redoCanvas() {
    if (!this.canvas.current) { return; } // catch error when canvas is not loaded yet
    this.canvas.current.redo();
  }

  setEraseMode(isActive) {
    if (!this.canvas.current) { return; } // catch error when canvas is not loaded yet
    this.setState({ eraseMode: isActive });
    this.canvas.current.eraseMode(isActive);
  }


  setPreset(preset) {
    let prompt = presetOptions[preset].prompt;
    let negprompt = presetOptions[preset].negprompt || "";
    this.setState({ preset, prompt, negprompt });
  }

  // run inpainting
  runInpainting() {
    const { image, mask, prompt, negprompt, imageWidth, imageHeight } = this.state;
    const { job_id } = this.props;
    this.setState({ isLoading: true })
    console.log("Running refinement")
    console.log("JOB ID", job_id)
    let inpaintingPrefilmServer = httpsCallable(this.props.functions, 'inpaintingPrefilmServer');
    console.log("PROMPT", prompt)
    console.log("NEGATIVE PROMPT", negprompt)
    inpaintingPrefilmServer({
      prompt,
      negprompt, 
      image_url: image,
      mask,
      width: 512,
      height: 512,
      denoising_strength: 1,
      steps: 40,
      cfg_scale: 7.0,
      restore_faces: true,
      inpaint_full_res: true,
      job_id
    })
    .then(async (result) => {
      console.log("Output", result, Date.now());
      const outputURL = result.data.url;
      console.log(outputURL);
      this.props.updateImage(outputURL)
      await this.canvas.current.clearCanvas();
      this.setState({ isLoading: false })
    }).catch((error) => {
      console.log(error);
    });
  }


  onChange = async () => {
    const paths = await this.canvas.current.exportPaths();

    // only respond if there are paths to draw (don't want to send a blank canvas)
    if (paths.length) {
      const data = await this.canvas.current.exportImage("svg");
      this.setState({ mask: data });
    }
  };



  render() {

    let { image, isVisible, closeModal } = this.props;
    const {
      prompt,
      imageWidth, 
      imageHeight,
      eraseMode,
      preset,
      strokeWidth,
      isLoading
      // replacementImage
    } = this.state;

    // if (replacementImage) {
    //   image = replacementImage;
    // }

    let userDescriptionTextArea = (
      <TextArea style={{ backgroundColor: 'white', color: 'black' }} value={prompt} onChange={(e) => this.setState({ prompt: e.target.value })} />
    );
    let instructionsText = "Draw a mask to fill in the area you want to replace or fix. Then describe the substitution below and press CREATE."

    if (presetOptions[preset].type === "CHARACTER") {
      userDescriptionTextArea = null;
      instructionsText = "Draw a mask to fill in the area you want to replace with your character. Then press CREATE."
    } else if (presetOptions[preset].type === "OBJECT") {
      userDescriptionTextArea = null;
      instructionsText = `Draw a mask to fill in the area you want to replace with a ${presetOptions[preset].text}. Then press CREATE.`
    }

    const presetOptionsArray = Object.keys(presetOptions).map((key) => ({ 
      // content: (<Image src={presetOptions[key].image} style={{ height: 100, width: 100 }} />),
      key, 
      value: key, 
      ...presetOptions[key] 
    }));

    return (
          <div className="refinement-content">
            <div className="refinement-playground">
              <div className="image-container" style={{ position: 'relative', width: imageWidth, height: imageHeight, userSelect: 'none' }}>
                    <Image
                        src={image}
                        onLoad={this.onImageLoad}
                        style={{ position: 'relative', width: imageWidth, height: imageHeight, userSelect: 'none' }}
                        alt="preview image"
                        layout="fill"
                      />
                    
                      <div
                          style={{ zIndex: 100, position: "absolute", top: 0, left: 0, width: imageWidth, height: imageHeight, userSelect: 'none' }}
                        >
                          <ReactSketchCanvas
                            ref={this.canvas}
                            strokeWidth={strokeWidth}
                            eraserWidth={strokeWidth}
                            strokeColor="white"
                            canvasColor="transparent"
                            onChange={this.onChange}
                            style={{ opacity: 1 }}
                          />
                  </div>
                  <Dimmer active={isLoading}>
                    <Loader disabled={!isLoading} size='medium' />
                  </Dimmer>
              </div>
              <div className="tools">
                <Form>
                  <div className="add-remove">
                    <Button icon 
                        style={{ backgroundColor: (!eraseMode ? "#5a07c3" : "#0d0d0d") }}
                        onClick={() => this.setEraseMode(false)}>
                      <Icon name="plus circle" />
                    </Button>
                    <Button icon
                        style={{ backgroundColor: (eraseMode ? "#5a07c3" : "#0d0d0d") }}
                        onClick={() => this.setEraseMode(true)}>
                      <Icon name="minus circle" />
                    </Button>
                  </div>
                  <Input label="Size" type="range" step={1} min={4} max={100} value={strokeWidth} onChange={(e) => this.setState({ strokeWidth: e.target.value })} />
                  <div className="horizontal-line" />

                  {/* <Dropdown
                    placeholder='Select Mode'
                    fluid
                    selection
                    options={presetOptionsArray}
                    onChange={(e, { value }) => this.setPreset(value)}
                    style={{ marginBottom: 20 }}
                  /> */}

                  <div style={{ zIndex: 1000, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', height: 400, overflowY: 'scroll' }}>
                    {presetOptionsArray.map(({ key, value, text, image }) => (
                      <div style={{ width: 80, height: 80, margin: 4, display: 'flex', justifyContent: 'center', alignItems: 'center', borderWidth: 2, borderStyle: 'solid', 
                              borderColor: (this.state.preset == value) ? '#0094ff' : 'white'
                          }} onClick={() => this.setPreset(value)}>

                        { image ? (
                            <Image
                              src={image}
                              style={{ position: 'relative', userSelect: 'none' }}
                              alt="preview image"
                              layout="fill"
                            />
                        ) : (
                          <div style={{ color: 'white' }}>{text}</div>
                        )}
                      </div>
                    ))}
                  </div>
                  
                  <div style={{ marginTop: 12 }} className="ui label single">{instructionsText}</div>
                  {userDescriptionTextArea}
                </Form>
                <Button id="create" style={{ marginTop: 16 }} icon onClick={this.runInpainting} disabled={isLoading}>
                  Create
                  <Icon name="magic" />
                </Button>
              </div>
            </div>
            <div className="refinement-controls">
              <div className="refinement-controls-container">
                <div className="undo-redo" style={{ marginTop: 10 }}>
                  <Button icon onClick={this.undoCanvas}>
                    <Icon name="undo"></Icon>
                    Undo
                  </Button>
                  <Button icon onClick={this.redoCanvas}>
                    <Icon name="redo"></Icon> Redo
                  </Button>
                <Button icon onClick={this.resetCanvas}>
                  <Icon name="trash alternate"></Icon>
                  Reset Canvas
                </Button>
                </div>
              </div>
              <div id="blocked-space"></div>
            </div>
          </div>
    );
  }
}

export default EditTab;

{/* <div className="add-remove">
                    <Button icon  
                        style={{ backgroundColor: (typeMode == "CHARACTER" ? "#5a07c3" : "#0d0d0d") }}
                        onClick={() => this.setTypeMode("CHARACTER")}>
                      Character
                    </Button>
                    <Button icon 
                        style={{ backgroundColor: (typeMode == "HARDHAT" ? "#5a07c3" : "#0d0d0d") }}
                        onClick={() => this.setTypeMode("HARDHAT")}>
                      Hard Hat
                    </Button>
                    <Button icon 
                        style={{ backgroundColor: (typeMode == "DIVINGSUIT" ? "#5a07c3" : "#0d0d0d") }}
                        onClick={() => this.setTypeMode("DIVINGSUIT")}>
                      Diving Suit
                    </Button>
                    <Button icon
                        style={{ backgroundColor: (typeMode == null ? "#5a07c3" : "#0d0d0d") }}
                        onClick={() => this.setTypeMode(null)}>
                      Other
                    </Button>
                  </div> */}
