import React, { Component } from "react";
import { Modal, Input, Button, Image, Icon, Form, TextArea } from "semantic-ui-react";

import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom';

import ShotTypeHits from './AlgoliaInfiniteSearch/ShotTypeHits';
import "./RefinementModal.css";

const searchClient = algoliasearch('PHMNSXEVLT', '99f100badfd34f3ea8f8415b9bc5d613');

class ShotTypeModal extends Component {


  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {

    let {  isVisible, closeModal, onSelectShotType } = this.props;

    return (
      <Modal className="refinement-modal" onClose={() => closeModal()} open={isVisible}>
        <Modal.Header>
          <h2>Select a shot type to recreate</h2>
          <h3>Pay attention to actor blocking, lighting style, camera framing, and general composition.</h3>
        </Modal.Header>
        <Modal.Content>
            <InstantSearch searchClient={searchClient} indexName="shotTypes">
              <SearchBox />
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', height: '70vh', overflowY: 'scroll' }}>
                <ShotTypeHits onSelect={(hit) => onSelectShotType(hit)} />
              </div>
            </InstantSearch>
        </Modal.Content>
      </Modal>
    )
  }
}

/*
<div className="refinement-content" style={{ display: 'flex', flexDirection: 'row', flexWrap:'wrap' }} >
{shotTypes.map((shotType, index) => (
                <Image 
                  key={index}
                  src={shotType.image}
                  style={{ width: 384, height: 160, objectFit: "cover", margin: 4 }}
                  onClick={() => onSelectShotType(shotType)}
                />
              ))}
            </div>
*/

export default ShotTypeModal;