import React, { Component } from "react";
import { Modal, Input, Button, Image } from "semantic-ui-react";

class FullscreenImageModal extends Component {


  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {

    let {  image, isVisible, closeModal } = this.props;

    return (
      <Modal className="refinement-modal" onClose={() => closeModal()} open={isVisible}>
        <Image
            src={image}
            alt="image"
            style={{ width: "100%" }}
          />
      </Modal>
    )
  }
}

export default FullscreenImageModal;