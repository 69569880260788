import React, { Component } from 'react';
import { connectInfiniteHits, Highlight } from 'react-instantsearch-dom';
import { Image, Segment, Loader } from "semantic-ui-react";
import PropTypes from 'prop-types';
import "./AlgoliaSearch.css";

class ShotTypeHits extends Component {
  static propTypes = {
    hits: PropTypes.arrayOf(PropTypes.object).isRequired,
    hasMore: PropTypes.bool.isRequired,
    refineNext: PropTypes.func.isRequired,
  };

  sentinel = null;

  onSentinelIntersection = entries => {
    const { hasMore, refineNext } = this.props;

    entries.forEach(entry => {
      if (entry.isIntersecting && hasMore) {
        refineNext();
      }
    });
  };

  componentDidMount() {
    this.observer = new IntersectionObserver(this.onSentinelIntersection);

    this.observer.observe(this.sentinel);
  }

  componentWillUnmount() {
    this.observer.disconnect();
  }

  render() {
    const { hits } = this.props;

  //   const Hit = ({ hit }) => (
  //     <Image 
  //       src={hit.image}
  //       style={{ width: 384, height: 160, objectFit: "cover", margin: 4, borderWidth: 3, borderColor: 'black', borderStyle: 'solid' }}
  //       onClick={() => onSelectShotType(hit)}
  //     />
  // );

    return (
      <div className="ais-InfiniteHits" style={{ width: "90%"  }}>
        <div className="ais-InfiniteHits-list" style={{  display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap'}}>
          {hits.map(hit => (
              <div 
                key={hit.objectID}
                className="ais-InfiniteHits-item"
                style={{ width: "33.3%", objectFit: 'cover', padding: 5, backgroundColor: 'transparent' }} 
                onClick={() => this.props.onSelect(hit)}
              >
                <Image src={hit.thumbnail} align="left" alt={hit.name} style={{ zIndex: 100 }}  />
              </div>
          ))}
          <div
            className="ais-InfiniteHits-sentinel"
            ref={c => (this.sentinel = c)}
          />
        </div>
      </div>
    );
  }
}

export default connectInfiniteHits(ShotTypeHits);
