import React, { Component } from "react";
import { Modal, Input, Button, Image, Segment, Icon, Form, TextArea, Loader, Dimmer } from "semantic-ui-react";

import EditIcon from "../assets/edit.svg";
import DownloadIcon from "../assets/download.svg";
import FullscreenIcon from "../assets/fullscreen.svg";
import TrashIcon from "../assets/trash.svg";

class HoverImage extends Component {


  constructor(props) {
    super(props);
    this.state = {

    }
  }


  componentDidMount() {
  }


  downloadLink(link) {
    console.log(link);
    fetch(link, {
      method: "GET",
      headers: {}
    })
      .then(response => {
        response.arrayBuffer().then(function(buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(err => {
        console.log(err);
      });
  };


  render() {

    // values for each size
    // const width = (is_storyboard) ? "40vw" : "70vw"
    // const height = "40vw"
    // const maxWidth = (is_storyboard) ? 600 : 1050;
    // const maxHeight = 600;

    // const width = "42vw";
    // const height = "17.5vw";
    // const maxWidth = 648;
    // const maxHeight = 270;

    // // let loading = !imageResult && !this.state.taskLoading;
    // let error = imageResult && imageResult.status !== "success";
    // // let errorMessage = "Image could not be loaded";

    // let imageContent = null;
    // if (imageResult) {
    //   if (imageResult.image) {
    //     imageContent = `data:image/jpeg;base64,${imageResult.image}`;
    //   } else if (imageResult) {
    //     imageContent = imageResult;
    //   }
    // }

    const { image, displayLoader, onEditImage, onFullscreenImage, onDeleteImage } = this.props;

    if (displayLoader) { 
      return (
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
          <Loader active size='large' />
        </div>
      );
    }

    if (image == "ERROR") {
      return (
        <Segment
          style={{
            margin: 5,
            padding: 0,
            borderRadius: 0,
            border: 0,
            backgroundColor: "#222222",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ fontSize: 12, width: "80%", maxWidth: 560, maxHeight: 320, color: "white", textAlign: "center" }}>
            {"Image could not be loaded"}
          </p>
        </Segment>
      );
    }

    // <Icon name='warning sign' size='large' color='orange' />

    return (
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
        <Image
          src={image}
          style={{ width: "100%", height: "100%" }}
        />
        <div 
          className="hoverOverlay"
          >
          <Image
              className="fullscreenButton"
              src={FullscreenIcon} 
              alt="fullscreen" 
              onClick={() => onFullscreenImage(image)} 
            />
            {/* <a href={image} download={`aifilm_${Date.now()}.png`} title={'AI FILM'}> */}
                <Image
                  className="downloadButton"
                  src={DownloadIcon} 
                  alt="download"
                  onClick={() => this.downloadLink(image)}
                />
              {/* </a> */}
            
            <Image
              className="editButton"
              src={EditIcon} 
              alt="edit" 
              onClick={() => onEditImage(image)} 
            />
            <Image
              className="trashButton"
              src={TrashIcon} 
              alt="trash" 
              onClick={() => onDeleteImage(image)} 
            />
        </div>
      </div>
    );

    // <Dimmer active={loading}>
    //         <Placeholder style ={{ width: "70vw", height: "40vw", maxWidth: 1050, maxHeight: 600}}  inverted />
    //     </Dimmer>
  }

}

export default HoverImage;