import React, { Component } from "react";
import { Modal, Tab } from "semantic-ui-react";
import TabProfile from "./SettingsTab/TabProfile";
import TabSubscriptions from "./SettingsTab/TabSubscriptions";

// import './Card.css';

class SettingsModal extends Component {
  render() {
    const { isVisible, closeModal } = this.props;

    const panes = [
      {
        menuItem: "Profile",
        render: () => <TabProfile />,
      },
      {
        menuItem: "Subscriptions",
        render: () => <TabSubscriptions />,
      },
    ];

    return (
      <Modal onClose={() => closeModal()} open={isVisible}>
        <Modal.Header>
          <div>Settings</div>
        </Modal.Header>
        <Modal.Content>
          <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
        </Modal.Content>
      </Modal>
    );
  }
}

export default SettingsModal;
